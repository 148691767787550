import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { FaList } from "react-icons/fa6";
import "bootstrap/js/dist/dropdown";
import "bootstrap-icons/font/bootstrap-icons.css";

function Coupon() {
  const [members, setMembers] = useState([
    {
      id: 1,
      couponname: "OSF-109",
      couponcode: "Paid",
      discounttype: "Paypal",
      discount: "Delivered",
    },
    {
      id: 2,
      couponname: "OSF-109",
      couponcode: "Paid",
      discounttype: "Paypal",
      discount: "Delivered",
    },
  ]);

  const handleDelete = (id) => {
    setMembers(members.filter((member) => member.id !== id));
  };
  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="panel-header bg-white shadow-sm">
          <div className="table-inner py-2 mb-2">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div className="">
                <div className="header d-sm-flex align-items-center text-center page-header">
                  <h3
                    className="banner-title"
                    style={{
                      marginLeft: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FaList style={{ color: "darkslateblue" }} /> COUPON LIST
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-inner transaction-details">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-title">
                  <div className="ml-auto">
                    <div className="ml-auto d-flex align-items-center">
                      <Form inline className="demo12">
                        <Link data-toggle="collapse" to="/AddCoupon">
                          <button class="btn12">Add New</button>
                        </Link>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive dataTables_scroll">
                    <table
                      id="basic-datatables"
                      className="display table table-hover table-fixed"
                    >
                      <thead>
                        <tr>
                          <th>S.NO</th>
                          <th>Coupon Name</th>
                          <th>Coupon Code</th>
                          <th>Discount Type</th>
                          <th>Discount</th>
                          <th>Status</th>

                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {members.map((member) => (
                          <tr key={member.id}>
                            <td>{member.id}</td>
                            <td>{member.couponname}</td>
                            <td>{member.couponcode}</td>
                            <td>{member.discounttype}</td>
                            <td>{member.discount}</td>
                            <td>{}</td>

                            <td className="text-right">
                              <div className="form-button-action">
                                <Link
                                  to="/"
                                  data-toggle="tooltip"
                                  title=""
                                  className="btn btn-link btn-primary "
                                  data-original-title="Edit"
                                >
                                  <i className="bi bi-pencil"></i>
                                </Link>
                                <button
                                  onClick={() => handleDelete(member.id)}
                                  data-toggle="tooltip"
                                  title=""
                                  className="btn btn-link btn-danger"
                                  data-original-title="Remove"
                                >
                                  <i className="bi bi-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Coupon;
