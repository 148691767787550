import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import singleCotImage from "../Assets/TW_SingleCot-ok.jpg";
import { postUrl } from "../apiservice/api";
import { UserContext } from "../context/UserProvider ";

function ProductList() {
  const{userDetails}=useContext(UserContext);
  const navigate=useNavigate();
  const location = useLocation();
  const subcatagory_id = location.state || {};
  console.log("suCatagory Id", subcatagory_id);
  const [products, setProducts] = useState([]);
  const[allupdateStatus,setAllupdateStatus]=useState(false);
  const [members, setMembers] = useState([
    {
      id: 1,
      productcode: "OSF-001",
      productname: "King Size Cot",
      size: "6.5x5 feet",
      color: "wood",
      description: "",
      rate: "15250",
    },
    {
      id: 2,
      productcode: "OSF-002",
      productname: "Small Size Cot",
      size: "3 seater",
      rate: "20500",
    },
  ]);
  const handleEdit=(id)=>{
    const product=products.filter(sin=>sin.product_id===id);
    console.log(product);
    navigate("/AddnewProduct",{state:{type:"edit",product:product,subcatagory_id:subcatagory_id}});
  }
  const handleDelete = async(id) => {
    const data={product_id:id};
    try {
      const result = await postUrl("product/delete.php", data);
      console.log(result); 
      if(result.status==200){
        alert("delete profuct successfully")
        setProducts(products.filter((member) => member.product_id !== id));
      }
      else{alert("failed to delete product")}
    } catch (error) {
      console.error("update all subRecords :", error);
    }

  };
  //product list
  const productList = async () => {
    // Data to be sent in the POST request
    const data = { sub_catagory_id: subcatagory_id }; // Replace with actual data

    try {
      const result = await postUrl("product/list.php", data);
      console.log("product list", result); // Handle the response data
      setProducts(result.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };


  //handle active change
  const handleActiveChange = (id, checked) => {
    setProducts(
      products.map((cat) =>
        cat.product_id === id ? { ...cat, active: checked?1:0 } : cat
      )
    );
  };

  const handleNewArrivalsChange=(id,checked)=>{
    setProducts(
      products.map((cat) =>
        cat.product_id === id ? { ...cat, new_arrival: checked?1:0 } : cat
      )
    );
  }

  const handlePopularChange=(id,checked)=>{
    setProducts(
      products.map((cat) =>
        cat.product_id === id ? { ...cat, popular: checked?1:0 } : cat
      )
    );
  }

  const handleSilkChange=(id,checked)=>{
    setProducts(
      products.map((cat) =>
        cat.product_id === id ? { ...cat, silk: checked?1:0 } : cat
      )
    );
  }

  const handleAllProductsUpdate=async(e)=>{
    e.preventDefault();
    const data = { products:products}; // Replace with actual data

    try {
      const result = await postUrl("product/allproductsupdate.php", data);
      console.log(result); 
      if(result.status==200){
        setAllupdateStatus(true);
        alert("all products uodate successfully")
      }
      else{alert("failed to update all products")}
    } catch (error) {
      console.error("update all subRecords :", error);
    }


  }
  console.log("products",products);
  useEffect(() => {
    productList();
  }, [allupdateStatus]);
  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="panel-header bg-white shadow-sm">
          <div className="table-inner py-2 mb-2">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div className="">
                <div className="header d-sm-flex align-items-center text-center">
                  <i
                    className="bi bi-list-task"
                    style={{
                      marginLeft: "20px",
                      height: "28px",
                      width: "28px",
                    }}
                  ></i>
                  <h3
                    className="banner-title"
                    style={{
                      marginLeft: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    List Of Products
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-inner transaction-details">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-title">
                  <div className="">
                    <div className="p-3 d-flex justify-content-end">
                      <Form inline className="me-3">
                        <Link
                          to="/subproduct"
                         
                        >
                          <button className="btn12">Back To SubCategory</button>
                        </Link>
                      </Form>

                      <Form inline className="">
                        <Link data-toggle="collapse" to="/AddnewProduct"  state={{ subcatagory_id: subcatagory_id }}>
                          <button className="btn12">Add New Product</button>
                        </Link>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive dataTables_scroll">
                    <div className="table-wrapper">
                      <table
                        id="basic-datatables"
                        className="display table table-hover table-fixed"
                      >
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Product Images</th>
                            <th>Product Code</th>
                            <th>Product Name</th>
                            <th>Color</th>
                            <th>Description</th>
                            <th>Rate</th>
                            <th>Silk Collections</th>
                            <th>Popular Silks</th>
                            <th>New Arrivals </th>
                            <th>Active</th>
                            <th className="text-right">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(products) &&
                            products.map((member, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <img
                                    src={`https://www.vedanthfashions.com/api/product/images/${member.image1}`}
                                    alt="Single Cot"
                                    style={{ width: "50px", height: "40px" }}
                                  />
                                </td>
                                <td>{member.product_code}</td>
                                <td>{member.product_name}</td>
                                <td>{member.color}</td>
                                <td>{member.description}</td>
                                <td>{member.rate}</td>
                                <td>
                                <input
                                  type="checkbox"
                                  checked={member.silk || false}
                                  onChange={(e) =>
                                    handleSilkChange(
                                      member.product_id,
                                      e.target.checked
                                    )
                                  }
                                  style={{ width: "20px", height: "20px" }}
                                />
                                </td>
                                <td>
                                <input
                                  type="checkbox"
                                  checked={member.popular || false}
                                  onChange={(e) =>
                                    handlePopularChange(
                                      member.product_id,
                                      e.target.checked
                                    )
                                  }
                                  style={{ width: "20px", height: "20px" }}
                                />
                                </td>
                                <td>
                                <input
                                  type="checkbox"
                                  checked={member.new_arrival || false}
                                  onChange={(e) =>
                                    handleNewArrivalsChange(
                                      member.product_id,
                                      e.target.checked
                                    )
                                  }
                                  style={{ width: "20px", height: "20px" }}
                                />
                                </td>
                                <td>
                                <input
                                  type="checkbox"
                                  checked={member.active || false}
                                  onChange={(e) =>
                                    handleActiveChange(
                                      member.product_id,
                                      e.target.checked
                                    )
                                  }
                                  style={{ width: "20px", height: "20px" }}
                                />
                                </td>
                                <td className="text-right">
                                  <div className="form-button-action">
                                    <button
                                    disabled={userDetails.update_product===0?true:false} 
                                    style={{cursor:userDetails.update_product==0?"not-allowed":"pointer"}}
                                      onClick={()=>{handleEdit(member.product_id)}}
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn btn-link btn-primary"
                                      data-original-title="Edit"
                                    >
                                      <i className="bi bi-pencil"></i>
                                    </button>
                                    <button
                                    disabled={userDetails.delete_product===0?true:false} 
                                    style={{cursor:userDetails.delete_product==0?"not-allowed":"pointer"}}
                                      onClick={() => handleDelete(member.product_id)}
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn btn-link text-danger"
                                      data-original-title="Remove"
                                    >
                                      <i className="bi bi-trash"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Form inline className="demo123">
                    <button className="btn12" disabled={userDetails.update_product===0?true:false} 
                                style={{cursor:userDetails.update_product==0?"not-allowed":"pointer"}}onClick={handleAllProductsUpdate}>Update</button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductList;
