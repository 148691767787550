import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Dropdown } from "react-bootstrap";
import "bootstrap/js/dist/dropdown";
import "bootstrap-icons/font/bootstrap-icons.css";
import { FaList } from "react-icons/fa6";
function OrderList() {
  const [members, setMembers] = useState([
    {
      id: 1,
      date: "21-06-2024",
      name: "AravindKumar",
      phonenumber: "2365897454",
      amount: "1250",
    },
    {
      id: 2,
      date: "21-06-2024",
      name: "AravindKumar",
      phonenumber: "2365897454",
      amount: "1250",
    },
  ]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const handleDelete = (id) => {
    setMembers(members.filter((member) => member.id !== id));
  };

  const handleDropdownSelect = (e) => {
    setSelectedOption(e);
  };

  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="panel-header bg-white shadow-sm">
          <div className="table-inner py-2 mb-2">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div className="text-center text-md-left mb-3 mb-md-0">
                <div className="header d-sm-flex align-items-center">
                  <h3 className="banner-title"style={{
                      marginLeft: "10px",
                      alignItems: "center ",
                      justifyContent: "center",
                    }}>
                    <FaList style={{ marginLeft: "20px", height: "28px" }}/> List Of Orders
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-inner transaction-details">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-title">
                  <div className="ml-auto">
                    <div className="ml-auto d-flex align-items-center">
                      <Form inline className="demo">
                        <Form.Group
                          controlId="formStartDate"
                          className="d-flex align-items-center mr-3"
                        >
                          <Form.Label className="mr-2">From: </Form.Label>
                          <Form.Control
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="formEndDate"
                          className="d-flex align-items-center mr-3"
                        >
                          <Form.Label className="mr-2">To: </Form.Label>
                          <Form.Control
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </Form.Group>
                        <Dropdown
                          onSelect={handleDropdownSelect}
                          className="mr-3"
                        >
                          <Dropdown.Toggle
                            className="btn12"
                            id="dropdown-basic"
                          >
                            {selectedOption || "Select Option"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey="Customer Name">
                              Customer Name
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="Order Id">
                              {" "}
                              Order Id
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="Mobile Number">
                              Mobile Number
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <button class="btn12 ">Submit</button>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive dataTables_scroll">
                    <table
                      id="basic-datatables"
                      className="display table table-hover table-fixed"
                    >
                      <thead>
                        <tr>
                          <th>Order ID</th>
                          <th>Date</th>
                          <th>Customer Name</th>
                          <th>Mobile</th>
                          <th>Amount</th>
                          <th>View</th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {members.map((member) => (
                          <tr key={member.id}>
                            <td>{member.id}</td>
                            <td>{member.date}</td>
                            <td>{member.name}</td>
                            <td>{member.phonenumber}</td>
                            <td>{member.amount}</td>
                            <td>
                              <Link data-toggle="collapse" to="/Invoice">
                                <button
                                  style={{
                                    border: "none",
                                    backgroundColor: "white",
                                  }}
                                >
                                  <i className="bi bi-eye"></i>
                                </button>
                              </Link>
                            </td>
                            <td className="text-right">
                              <div className="form-button-action">
                                <button
                                  onClick={() => handleDelete(member.id)}
                                  data-toggle="tooltip"
                                  title=""
                                  className="btn btn-link btn-danger"
                                  data-original-title="Remove"
                                >
                                  <i className="bi bi-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderList;
