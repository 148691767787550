import { useContext, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../Assets/logo (1).png";
import "../css/login.css";
import axios from "axios";
import { postUrl } from "../apiservice/api";
import { AssignmentLateRounded } from "@mui/icons-material";
import { UserContext } from "../context/UserProvider ";

function Login() {
  const{setUserDetails,setStatus}=useContext(UserContext)
  const [validated, setValidated] = useState(false);
  const [username, setUsername] = useState(""); // State for username
  const [password, setPassword] = useState(""); // State for password

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    // You can now use the username and password states to handle the login logic
    console.log("Username:", username);
    console.log("Password:", password);

    const response = await postUrl("user/login.php", {
      email: username,
      password: password,
    });
    console.log(response);
    if(response.status===200){
      alert("success full login")
      setStatus(curr=>!curr)
      setUserDetails(response.user)
      localStorage.setItem("user",response.user.user_id);
    }
    else{
      alert(response.error)
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value); // Update the username state
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value); // Update the password state
  };

  return (
    <Container fluid className="d-flex justify-content-center align-items-center min-vh-100 login_bg">
      <Card style={{ width: "100%", maxWidth: "400px", padding: "20px" }}>
        <Card.Body>
          <img src={logo} alt="" className="tab-img img-fluid m-auto mb-3" />
          <Card.Title className="text-center mb-4 title">
            Admin Login
          </Card.Title>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="validationCustom01">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Username"
                  value={username} // Bind the value to the username state
                  onChange={handleUsernameChange} // Handle the change event
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="validationCustom02">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Password"
                  value={password} // Bind the value to the password state
                  onChange={handlePasswordChange} // Handle the change event
                />
              </Form.Group>
            </Row>
            <Button type="submit" className="w-50 submit mt-2">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Login;
