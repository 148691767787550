import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { IoBagOutline } from "react-icons/io5";
import { RiStockLine } from "react-icons/ri";
import { postUrl } from "../apiservice/api";
import { UserContext } from "../context/UserProvider ";
import { FaList } from "react-icons/fa";

function Furniture() {
  const{userDetails}=useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const main_catagory_id =
    location.state || localStorage.getItem("main_catagory_id");
  const [subcatagorys, setSubcatagorys] = useState([]);
  const handleEdit = (id) => {
    const subcatagory = subcatagorys.filter((sin) => sin.subcatagory_id === id);
    navigate("/AddSubcategory", {
      state: { type: "edit", subcatagory: subcatagory },
    });
  };

  const handleDelete = async(id) => {
    const data = { subcatagory_id:id}; // Replace with actual data
    try {
      const result = await postUrl("subcatagory/delete.php", data);
      console.log(result); 
      if(result.status==200){alert("delete subProduct success");setSubcatagorys(subcatagorys.filter((member) => member.subcatagory_id !== id));}
      else{alert("failed to delete subCatagor")}
    } catch (error) {
      console.error("update all subRecords :", error);
    }
    
  };

  const handleActiveChange = (id, checked) => {
    setSubcatagorys(
      subcatagorys.map((cat) =>
        cat.subcatagory_id === id ? { ...cat, active: checked?1:0 } : cat
      )
    );
  };

  const allSubCategoryUpdate=async(e)=>{
    e.preventDefault();
    const data = { sub_categorys:subcatagorys}; // Replace with actual data

    try {
      const result = await postUrl("subcatagory/allsubcategoryupdate.php", data);
      console.log(result); 
      if(result.status==200){alert("successfully uddated all subcatagories")}
      else{alert("failed to  update all subCatagories")}
    } catch (error) {
      console.error("update all subRecords :", error);
    }
  }
  const listSubProduct = async () => {
    // Data to be sent in the POST request
    const data = { main_catagory_id: main_catagory_id }; // Replace with actual data

    try {
      const result = await postUrl("subcatagory/list.php", data);
      console.log(result); // Handle the response data
      setSubcatagorys(result.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };
  useEffect(() => {
    listSubProduct();
  }, []);
  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="panel-header bg-white shadow-sm">
          <div className="table-inner py-2 mb-2">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div className="">
                <div className="header d-sm-flex align-items-center text-center">
                  
                  <h3
                    className="banner-title"
                    style={{
                      marginLeft: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FaList style={{ marginLeft: "20px", height: "28px" }}/>  List Of Sub Category
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-inner transaction-details">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-title">
                  <div className="p-3">
                    <div className="d-flex justify-content-end">
                      <Form inline className="me-3">
                        <Link data-toggle="collapse" to="/products">
                          <button class="btn12 ">Back To Main Category</button>
                        </Link>
                      </Form>

                      <Form inline className="">
                        <Link
                          data-toggle="collapse"
                          to="/AddSubcategory"
                          state={main_catagory_id}
                        >
                          <button class="btn12">Add Sub Category</button>
                        </Link>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive dataTables_scroll">
                    <table
                      id="basic-datatables"
                      className="display table table-hover table-fixed"
                    >
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Sub Category</th>
                          <th>Active</th>
                          <th>Product</th>
                          <th>Stock</th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(subcatagorys) &&
                          subcatagorys.map((member, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{member.catagory_name}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={member.active || false}
                                  onChange={(e) =>
                                    handleActiveChange(
                                      member.subcatagory_id,
                                      e.target.checked
                                    )
                                  }
                                  style={{ width: "20px", height: "20px" }}
                                />
                              </td>

                              <td>
                                <Link
                                  data-toggle="collapse"
                                  to="/ProductList"
                                  state={member.subcatagory_id}
                                >
                                  <button
                                    style={{
                                      border: "none",
                                      backgroundColor: "white",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  >
                                    <IoBagOutline
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        color: "brown",
                                      }}
                                    />
                                  </button>
                                </Link>
                              </td>
                              <td>
                                <Link data-toggle="collapse" to="/Stock" state={member.subcatagory_id}>
                                  <button
                                    style={{
                                      border: "none",
                                      backgroundColor: "white",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  >
                                    <RiStockLine
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        color: "brown",
                                      }}
                                    />
                                  </button>
                                </Link>
                              </td>
                              <td className="text-right">
                                <div className="form-button-action">
                                  <button
                                    onClick={() => {
                                      handleEdit(member.subcatagory_id);
                                    }}

                                    disabled={userDetails.update_product===0?true:false} 
                                style={{cursor:userDetails.update_product==0?"not-allowed":"pointer"}}
                                    data-toggle="tooltip"
                                    title=""
                                    className="btn btn-link text-danger"
                                    data-original-title="Edit"
                                  >
                                    <i className="bi bi-pencil"></i>
                                  </button>
                                  <button
                                  disabled={userDetails.delete_product===0?true:false} 
                                  style={{cursor:userDetails.delete_product==0?"not-allowed":"pointer"}}
                                    onClick={() => handleDelete(member.subcatagory_id)}
                                    data-toggle="tooltip"
                                    title=""
                                    className="btn btn-link text-danger"
                                    data-original-title="Remove"
                                  >
                                    <i className="bi bi-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Form inline className="demo123">
                    <button class="btn12 " disabled={userDetails.update_product===0?true:false} 
                                style={{cursor:userDetails.update_product==0?"not-allowed":"pointer"}}onClick={allSubCategoryUpdate}>Update</button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Furniture;
