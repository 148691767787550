import React, { useContext, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserProvider ";

function AddUser() {
  const { userDetails } = useContext(UserContext);
  const [preview, setPreview] = useState(null);
  const location = useLocation();
  const { type, user } = location.state || {};
  const navigate = useNavigate();

  // Initialize state for form fields
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phonenumber: "",
    adhaarno: "",
    role: "",
    password: "",
    image: null,
    add_product: 0,
    update_product: 0,
    delete_product: 0,
    order: 0,
    banner: 0,
    content_management: 0,
  });

  const [crtFormdata, setCrtFormdata] = useState(
    type === "edit" ? (user && user[0]) || {} : {}
  );

  // Handle changes for text inputs
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (type === "edit") {
      setCrtFormdata((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  // Handle file input changes
  const handleFileChange = (e) => {
    const { id, files } = e.target;
    if (files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);

      if (type === "edit") {
        setCrtFormdata((prevData) => ({
          ...prevData,
          [id]: file,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [id]: file,
        }));
      }
    }
  };

  // Handle permission changes
  const handlePermissionChange = (e) => {
    const { name, value } = e.target;
    if (type === "edit") {
      setCrtFormdata((prevData) => ({
        ...prevData,
        [name]: parseInt(value, 10),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: parseInt(value, 10),
      }));
    }
  };
  console.log("formData",formData)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const response = await axios.post(
        "https://www.vedanthfashions.com/api/user/createuser.php",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.status === 200) {
        alert("User Created successfully");
        setFormData({
          name: "",
          email: "",
          phonenumber: "",
          adhaarno: "",
          role: "",
          password: "",
          image: null,
          add_product: 0,
          update_product: 0,
          delete_product: 0,
          order: 0,
          banner: 0,
          content_management: 0,
        });
        setPreview(null);
      } else {
        alert("Failed to add user");
      }
    } catch (error) {
      console.error("Error uploading files", error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("user_id", crtFormdata.user_id);
    for (const key in crtFormdata) {
      data.append(key, crtFormdata[key]);
    }

    try {
      const response = await axios.post(
        "https://www.vedanthfashions.com/api/user/updateuser.php",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.status === 200) {
        alert("User updated successfully");
        navigate("/user");
      } else {
        alert("Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user", error);
    }
  };

  return (
    <div className="main-panel">
      <div className="content">
        <div className="page-inner">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-head-row">
                    <div className="card-title">
                      <h3>{type === "edit" ? "Edit User" : "Add New User"}</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="main-form full">
                    <div className="row">
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label
                              htmlFor="name"
                              className="col-md-3 control-label"
                            >
                              Name
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                value={
                                  type === "edit"
                                    ? crtFormdata.name
                                    : formData.name
                                }
                                onChange={handleInputChange}
                                required
                                placeholder="Name"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label
                              htmlFor="email"
                              className="col-md-3 control-label"
                            >
                              Email
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                value={
                                  type === "edit"
                                    ? crtFormdata.email
                                    : formData.email
                                }
                                onChange={handleInputChange}
                                required
                                placeholder="Email"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label
                              htmlFor="phonenumber"
                              className="col-md-3 control-label"
                            >
                              Phone Number
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="text"
                                className="form-control"
                                id={
                                  type === "edit"
                                    ? "phonenumber"
                                    : "phonenumber"
                                }
                                value={
                                  type === "edit"
                                    ? crtFormdata.phonenumber
                                    : formData.phonenumber
                                }
                                onChange={handleInputChange}
                                required
                                placeholder="Phone Number"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label
                              htmlFor="adhaarno"
                              className="col-md-3 control-label"
                            >
                              Aadhaar No
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="text"
                                className="form-control"
                                id={type === "edit" ? "adhaarno" : "adhaarno"}
                                value={
                                  type === "edit"
                                    ? crtFormdata.adhaarno
                                    : formData.adhaarno
                                }
                                onChange={handleInputChange}
                                required
                                placeholder="Aadhaar No"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label
                              htmlFor="role"
                              className="col-md-3 control-label"
                            >
                              Role
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="text"
                                className="form-control"
                                id="role"
                                value={
                                  type === "edit"
                                    ? crtFormdata.role
                                    : formData.role
                                }
                                onChange={handleInputChange}
                                required
                                placeholder="Role"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label
                              htmlFor="password"
                              className="col-md-3 control-label"
                            >
                              Password
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                value={
                                  type === "edit"
                                    ? crtFormdata.password
                                    : formData.password
                                }
                                onChange={handleInputChange}
                                required
                                placeholder="Password"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-box form-group">
                          <div className="row">
                            <label
                              htmlFor="image"
                              className="col-md-3 control-label"
                            >
                              Upload Image
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="file"
                                className="form-control"
                                id="image"
                                onChange={handleFileChange}
                              />
                              {preview ? (
                                <img
                                  src={preview}
                                  // alt="Preview"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    marginTop: "10px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={`https://www.vedanthfashions.com/api/user/usersimages/${crtFormdata.image}`}
                                  alt="Preview"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    marginTop: "10px",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <h5>Add Privileges</h5>
                        </div>
                        {[
                          "add_product",
                          "update_product",
                          "delete_product",
                          "order",
                          "banner",
                          "content_management",
                        ].map((item, index) => (
                          <div className="col-12" key={index}>
                            <div className="input-box form-group">
                              <div className="row">
                                <label className="col-md-3 control-label">
                                  {item}
                                </label>
                                <div className="col-md-9 d-flex align-items-center">
                                  <label className="form-radio-label me-3">
                                    <input
                                      type="radio"
                                      name={item}
                                      value={1}
                                      checked={type==="edit"?crtFormdata[item]===1:formData[item] === 1}
                                      onChange={handlePermissionChange}
                                    />
                                    Allow
                                  </label>
                                  <label className="form-radio-label">
                                    <input
                                      type="radio"
                                      name={item}
                                      value={0}
                                      checked={type==="edit"?crtFormdata[item]===0:formData[item] === 0}
                                      onChange={handlePermissionChange}
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <div className="row ">
                            <div className="col-md-3"></div>
                            <div className="mt-4 d-flex justify-content-end">
                              {type === "edit" ? (
                                <button
                                  disabled={
                                    userDetails.update_product === 0
                                      ? true
                                      : false
                                  }
                                  style={{
                                    cursor:
                                      userDetails.update_product == 0
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                  onClick={handleUpdate}
                                  type="submit"
                                  className="btn123"
                                >
                                  update
                                </button>
                              ) : (
                                <button
                                  disabled={
                                    userDetails.add_product === 0 ? true : false
                                  }
                                  style={{
                                    cursor:
                                      userDetails.add_product == 0
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                  onClick={handleSubmit}
                                  type="button"
                                  className="btn123"
                                >
                                  Submit
                                </button>
                              )}
                              <button
                                type="button"
                                className="btn12 mx-2"
                                onClick={() => {
                                  navigate(-1);
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
