import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { IoReorderFourSharp } from "react-icons/io5";
import "bootstrap/js/dist/dropdown";
import "bootstrap-icons/font/bootstrap-icons.css";
import Logo from "../Assets/member.jpg";
import { getUrl, postUrl } from "../apiservice/api";
import { UserContext } from "../context/UserProvider ";

function User() {
  const{userDetails}=useContext(UserContext);
  const navigate=useNavigate();
  

  const[users,setUsers]=useState([]);
  const editClick=(id)=>{
    const user=users.filter(sin=>sin.user_id===id);
    navigate("/AddUser",{state:{type:"edit",user:user}})
  }
  const handleDelete = async(id) => {
    try {
      const response=await postUrl("user/deleteuser.php",{user_id:id});
      setUsers(users.filter((member) => member.user_id !== id));
      console.log("delete response",response);
    } catch (error) {
      console.log("delete user error",error.name,"==>",error.message);
    }
    
  };

  const userList = async () => {
    try {
      // Await the result from getUrl
      const result = await getUrl("user/listuser.php");
      console.log(result.data)
      setUsers(result.data)
    } catch (error) {
      console.log("list user error", error.name, "==>", error.message);
    }
  };
  useEffect(()=>{
    userList()
  },[])
  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="panel-header bg-white shadow-sm">
          <div className="table-inner py-2 mb-2">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div className="">
                <div className="header d-sm-flex align-items-center text-center">
                  <h3
                    className="banner-title"
                    style={{
                      marginLeft: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IoReorderFourSharp /> User List
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-inner transaction-details">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-title">
                  <div className="ml-auto">
                    <div className="ml-auto d-flex align-items-center">
                      <Form inline className="demo12">
                        <Link data-toggle="collapse" to="/AddUser">
                          <button class="btn12 ">Add User</button>
                        </Link>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive dataTables_scroll">
                    <table
                      id="basic-datatables"
                      className="display table table-hover table-fixed"
                    >
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Profile</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone Number</th>
                          <th>Aadhaar No</th>
                          <th>Role</th>

                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(users)&&users.map((member,index) => (
                          <tr key={index}>
                            <td>{index+1}</td>
                            <td>
                              <img
                                src={`https://www.vedanthfashions.com/api/user/usersimages/${member.image}`}
                                alt="Single Cot"
                                style={{ width: "50px", height: "40px" }}
                              />
                            </td>
                            <td>{member.name}</td>
                            <td>{member.email}</td>
                            <td>{member.phonenumber}</td>
                            <td>{member.adhaarno}</td>
                            <td>{member.role}</td>

                            <td className="text-right">
                              <div className="form-button-action">
                                <button
                                disabled={userDetails.update_product===0?true:false} 
                                style={{cursor:userDetails.update_product==0?"not-allowed":"pointer"}}
                                  onClick={() => {editClick(member.user_id)}}
                                  data-toggle="tooltip"
                                  title=""
                                  className="btn btn-link text-danger"
                                  data-original-title="Edit"
                                >
                                  <i className="bi bi-pencil"></i>
                                </button>
                                <button
                                disabled={userDetails.delete_product===0?true:false} 
                                style={{cursor:userDetails.delete_product==0?"not-allowed":"pointer"}}
                                  onClick={() => {handleDelete(member.user_id)}}
                                  data-toggle="tooltip"
                                  title=""
                                  className="btn btn-link text-danger"
                                  data-original-title="Remove"
                                >
                                  <i className="bi bi-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default User;
