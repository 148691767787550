import React, { useContext, useEffect } from "react";
import "bootstrap/js/dist/dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { UserContext } from "../context/UserProvider ";
import { postUrl } from "../apiservice/api";
import { useNavigate } from "react-router-dom";
/* import Sidebar from "../Sidebar";
import Nav from "../Nav"; */

function Dashboard() {
  const navigate=useNavigate();
   const{userDetails}=useContext(UserContext);
   console.log(userDetails);
  // const getUser=async()=>{
  //   const data={user_id:localStorage.getItem("user")};
  //   console.log(data);
  //   try {
  //     const response=await postUrl("user/singleuser.php",data);
  //     if(response.status!==200){
  //       navigate("/")
  //     }
  //   } catch (error) {
  //     console.log("context error",error.name,"==>",error.message);
  //   }
  // }
  // useEffect(()=>{
  //   getUser();
  // },[])

  return (
    <div class="main-panel mt-0">
      <div class="content mt-4">
        <div className="row">
          {/* <!-- Statistics data --> */}
          <div className="statistics mt-0">
            <div className="row">
              <div className="col-xl-6">
                <div className="row">
                  <div className="col-sm-6 ">
                    <div className="statistics-grid card bg-light-primary px-4 py-3 py-sm-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="statistics-icon">
                          <i class="bi bi-cart"></i>
                        </div>
                        <div className="statistics-detail">
                          <p className="stat-text">Total Orders</p>
                          <h3 className="number">
                            26K<small>This Month</small>
                          </h3>
                        </div>
                      </div>
                      <div className="tile-footer pt-2">
                        <a href="#">View more...</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <div className="statistics-grid card bg-light-secondary px-4 py-3 py-sm-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="statistics-icon">
                          <i class="bi bi-credit-card"></i>
                        </div>
                        <div className="statistics-detail">
                          <p className="stat-text">Total Sales </p>
                          <h3 className="number">
                            21.5K<small>This Month</small>
                          </h3>
                        </div>
                      </div>
                      <div className="tile-footer pt-2">
                        <a href="#">View more...</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="row">
                  <div className="col-sm-6 ">
                    <div className="statistics-grid card bg-light-info px-4 py-3 py-sm-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="statistics-icon">
                          <i class="bi bi-people"></i>
                        </div>
                        <div className="statistics-detail">
                          <p className="stat-text">Total Customers </p>
                          <h3 className="  number">
                            15K<small>This Month</small>
                          </h3>
                        </div>
                      </div>
                      <div className="tile-footer pt-2">
                        <a href="#">View more...</a>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 ">
                    <div className="statistics-grid card bg-light-info px-4 py-3 py-sm-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="statistics-icon">
                          <i class="bi bi-people"></i>
                        </div>
                        <div className="statistics-detail">
                          <p className="stat-text">No of Products </p>
                          <h3 className="  number">
                            86<small></small>
                          </h3>
                        </div>
                      </div>
                      <div className="tile-footer pt-2">
                        <a href="#">View more...</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  <!-- Statistics data --> */}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
