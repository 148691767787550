import React, { useContext, useState } from "react";
import { postUrl } from "../apiservice/api";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserProvider ";

function AddMaincategory() {
  const navigate=useNavigate();
  const location = useLocation();
  const { type, maincategory } = location.state || {};
  const {userDetails } = useContext(UserContext);
  console.log(userDetails)
  const [categoryName, setCategoryName] = useState("");
  const [currentCategoryName, setCurrentCategoryName] = useState(maincategory?.[0]?.category_name || "");

  const handleChange = (e) => {
    if (type === "edit") {
      setCurrentCategoryName(e.target.value);
    } else {
      setCategoryName(e.target.value);
    }
    console.log("catagoryname",categoryName);
    console.log("setCurrent CatagoryName",currentCategoryName);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      const response = await postUrl("catagory/insert.php", { category_name: categoryName });
      if (response.status === 200) {
        alert("Main Category Inserted Successfully");
        setCategoryName("");
        navigate(-1);
        
      } else {
        alert(response.message);
      }
    } catch (error) {
      console.error("Error saving category:", error);
      alert("An error occurred while saving the category.");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await postUrl("catagory/update.php", { main_catagory_id: maincategory[0].main_catagory_id, category_name: currentCategoryName });
      console.log("resposnse",response)
      if (response.status === 200) {
        alert("sucessfully updated")
        setCurrentCategoryName("")
        navigate(-1);
      } else {
        alert("update failed")
      }
    } catch (error) {
      console.error("Error updating category:", error);
      alert("An error occurred while updating the category.");
    }
  };

  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="page-inner">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-head-row">
                    <div className="card-title">
                      <h3>{type === "edit" ? "Edit Main Category" : "Add Main Category"}</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="main-form full">
                    <div className="row">
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label htmlFor="category-name" className="col-md-3 control-label">
                              Category Name
                            </label>
                            <div className="col-md-5">
                              <input
                                type="text"
                                className="form-control"
                                id="category-name"
                                name="category_name"
                                value={type === "edit" ? currentCategoryName : categoryName}
                                onChange={handleChange}
                                placeholder="Category Name"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 d-flex justify-content-end">
                        {type === "edit" ? (
                          <button className="btn12" onClick={handleUpdate} disabled={userDetails.update_product===0?true:false} style={{cursor:userDetails.update_product==0?"not-allowed":"pointer"}}>Update</button>
                        ) : (
                          <button className="btn12" onClick={handleSave} disabled={userDetails.add_product===0?true:false} style={{cursor:userDetails.add_product==0?"not-allowed":"pointer"}}>Save</button>
                        )}
                        <button className="btn12 ms-2">Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMaincategory;
