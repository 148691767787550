import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link, useNavigate } from "react-router-dom";
import { FaList } from "react-icons/fa";
import axios from "axios";
import { getUrl, postUrl } from "../apiservice/api";
import { UserContext } from "../context/UserProvider ";
import '../css/product.css'
function Products({setLoggedIn}) {
  const {userDetails } = useContext(UserContext);
  console.log("userDetails product page",userDetails);
  const navigate = useNavigate();
  const[maincategorys,setMainCategory]=useState([]);
  const[allUpdateStatus,setAllUpdateStatus]=useState(false);
 
  const handleNavigate = (maincategory,data) => {
    navigate(`/${maincategory}`,{state:data});
  };


  const subCatagoryButtonClick=(main_catagory_id)=>{
    localStorage.setItem("main_catagory_id",main_catagory_id);
    handleNavigate("subproduct",main_catagory_id);

    
  }

  const handleEdit=(main_catagory_id)=>{
    const maincategory=maincategorys.filter(sin=>sin.main_catagory_id===main_catagory_id);
    handleNavigate("AddMaincategory",{type:"edit",maincategory:maincategory});
  }
  const handleRankChange = (id, value) => {
    setMainCategory(maincategorys.map((cat) =>
      cat.main_catagory_id === id ? { ...cat, rank: value } : cat
    ));
  };

  const handleActiveChange = (id, checked) => {
    setMainCategory(maincategorys.map((cat) =>
      cat.main_catagory_id === id ? { ...cat, active: checked?1:0 } : cat
    ));
  };

  const handleDelete =async(id) => {
    try {
      const response = await postUrl("catagory/delete.php", { main_category_id: id });
      if (response.status === 200) {
        alert("delete successfully Successfully");
      } else {
        alert("main CAtagory deleted failed");
      }
    } catch (error) {
      console.error("Error delete category:", error);
    }
    setMainCategory(maincategorys.filter(sin=>sin.main_catagory_id!==id));
  };


  const allMainCategoryUpdate=async(e)=>{
    e.preventDefault();
    try {
      const response = await postUrl("catagory/allmaincategoryupdate.php", { main_categorys:maincategorys });
      if (response.status === 200) {
        setAllUpdateStatus(curr=>!curr)
        alert("update all main cataroies Successfully");

      } else {
        alert("update all main cataroies failed");
      }
    } catch (error) {
      console.error("Error delete category:", error);
    }
  }
  async function listCategory() {
    try {
      const data = await getUrl("catagory/list.php");
      console.log(data.data); 

      setMainCategory(data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }
 
  useEffect(()=>{
    console.log("list category update changed")
    if(userDetails===null){
      return
    }
    else{
      listCategory()
    }
  },[allUpdateStatus,userDetails])
  console.log("userDetails",userDetails)
  return (
    <div className="main-panel mt-0">
      <div className="content mt-0">
        <div className="panel-header bg-white shadow-sm">
          <div className="table-inner py-2 my-2">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div className="">
                <div className="header d-sm-flex align-items-center text-center">
                  <h3
                    className="banner-title"
                    style={{
                      marginLeft: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FaList style={{ marginLeft: "20px", height: "28px" }}/> List Of Main Category
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-inner transaction-details">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-title">
                  <div className="ml-auto">
                    <div className="ml-auto d-flex align-items-center">
                      <Form inline className="demo12" style={{gap:"20px"}}>
                      <button type="button" class="btn12 " onClick={()=>{navigate(-1)}}>
                        Back
                        </button>
                        <button class="btn12 ">
                          <Link
                            data-toggle="collapse"
                            to="/AddMaincategory" 
                            style={{ color: "white" }}
                          >
                            Add Main Category
                          </Link>
                        </button>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive dataTables_scroll">
                    <table
                      id="basic-datatables"
                      className="display table table-hover table-fixed"
                    >
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Main Category</th>
                          <th>Rank</th>
                          <th>Active</th>
                          <th>Sub Category</th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(maincategorys)&&maincategorys.map((member,index) => (
                          <tr key={index}>
                            <td>{index+1}</td>
                            <td>{member.category_name}</td>
                            <td>
                            <input
                                type="number"
                                value={member.rank || ''}
                                onChange={(e) => handleRankChange(member.main_catagory_id, e.target.value)}
                                className="form-control form-control-sm"
                                style={{ width: "60px", height: "30px", border: "1px solid black" }}
                              />
                            </td>

                            <td>
                            <input
                                type="checkbox"
                                checked={member.active || false}
                                onChange={(e) => handleActiveChange(member.main_catagory_id, e.target.checked)}
                                style={{ width: "20px", height: "20px" }}
                              />
                            </td>
                            <td>
                              <button
                                style={{
                                  border: "none",
                                  backgroundColor: "white",
                                  width: "30px",
                                  height: "30px",
                                }}
                                onClick={() =>{subCatagoryButtonClick(member.main_catagory_id)}}
                              >
                                <FaList
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    color: "brown",
                                  }}
                                />
                              </button>
                            </td>
                            <td className="text-right">
                              <div className="form-button-action">
                                <button
                                disabled={userDetails.update_product===0?true:false} 
                                style={{cursor:userDetails.update_product==0?"not-allowed":"pointer"}}
                                  onClick={()=>{handleEdit(member.main_catagory_id)}}
                                  data-toggle="tooltip"
                                  title=""
                                  className="btn btn-link text-danger"
                                  data-original-title="Edit"
                                >
                                  <i className="bi bi-pencil"></i>
                                </button>
                                <button
                                disabled={userDetails.delete_product===0?true:false} 
                                style={{cursor:userDetails.delete_product==0?"not-allowed":"pointer"}}
                                  onClick={() => handleDelete(member.main_catagory_id)}
                                  data-toggle="tooltip"
                                  title=""
                                  className="btn btn-link text-danger"
                                  data-original-title="Remove"
                                >
                                  <i className="bi bi-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Form inline className="demo123">
                    <button class="btn12 " onClick={allMainCategoryUpdate} disabled={userDetails.update_product===0?true:false} style={{cursor:userDetails.update_product==0?"not-allowed":"pointer"}} >Update</button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
