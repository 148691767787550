import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
	return (
		<div className="main">
			<div className="row">
				<div className="col-12">

					<footer class="footer">
						<div class="container-fluid d-flex justify-content-center">
							<nav class="pull-left">
								<ul class="nav">

									<li class="nav-item">
										<a class="nav-link" href="#">
											Help
										</a>
									</li>
									<li class="nav-item">
										<a class="nav-link" href="#">
											Licenses
										</a>
									</li>
								</ul>
							</nav>
							<div className='space'></div>
							<div class="copyright ml-auto">
								© 2024 All Rights Reserved. Design By <Link to="https://aatheshsoft.com/">Aathesh Soft</Link>
							</div>
						</div>
					</footer>
				</div>
			</div>

		</div>

	)
}

export default Footer