import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { postUrl } from '../apiservice/api';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [status, setStatus] = useState(false);

  const getUser = async () => {
    const userId = localStorage.getItem("user");
    if (userId) {
      const data = { "user_id": userId };
      try {
        const response = await postUrl("user/singleuser.php", data);
        console.log("Response from server:", response);

        if (response.status === 200) {
          if (response.data && response.data.user_id) {
            localStorage.setItem("user", response.data.user_id);
            setUserDetails(response.data);
          } else {
            console.log("User data is missing or incorrect in response");
            localStorage.removeItem("user");
          }
        }
      } catch (error) {
        console.log("context error", error.name, "==>", error.message);
        localStorage.removeItem("user");
      }
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <UserContext.Provider value={{ userDetails, setUserDetails, setStatus }}>
      {children}
    </UserContext.Provider>
  );
};
