import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Nav from "../Nav";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import { UserContext } from "../context/UserProvider ";

const Layout = () => {
  return (
    <>
      <Nav />
      <Sidebar/>
      <main>
        <Outlet/>
      </main>
      <Footer />
    </>
  );
};

export default Layout;
