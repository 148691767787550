import React from "react";
import "bootstrap/js/dist/dropdown";
import "bootstrap-icons/font/bootstrap-icons.css";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Link } from "react-router-dom";

import logo from "./Assets/544-5445462_people-icons-png-flat-person-icon-png-transparent.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

function Nav() {
  return (
    <div className="main">
      <nav className="navbar navbar-header navbar-expand-lg">
        <div className="container-fluid">
          <ul className="navbar-nav topbar-nav ms-auto">
            <li className="nav-item">
              <DropdownButton
                as={ButtonGroup}
                title={
                  <span className="dpbutton">
                    <img
                      src={logo}
                      alt="Logo"
                      className="btn-rounded"
                      style={{
                        width: "34px",
                        height: "38px",
                        padding: "3px",
                      }}
                    />
                    <span style={{fontSize:"14px"}}>Admin</span> <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                }
                id="admin-dropdown"
                style={{ width: "120px" }}
              >
                <Dropdown.Item as={Link} to="/profile">
                  <i className="bi bi-person"></i> Profile
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/settings">
                  <i className="bi bi-gear"></i> Settings
                </Dropdown.Item>
                <Dropdown.Item>
                  <i className="bi bi-box-arrow-right"></i> Logout
                </Dropdown.Item>
              </DropdownButton>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Nav;
