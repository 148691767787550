import React, { useEffect, useState } from 'react';
import { postUrl } from '../apiservice/api';

const Settings = () => {
  const [formValues, setFormValues] = useState({
    settings_id: "settings_66d05f81218b29.37766446",
    email: "",
    gst_number: "",
    igst_number: "",
    sgst_number: "",
    minimum_order: "",
    address: "",
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Fetch current settings
  async function listSetting() {
    try {
      const response = await postUrl("settings/settingsbyID.php", { settings_id: formValues.settings_id });
      setFormValues(response.data);
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  }

  // Handle settings update
  const handleUpdate = async () => {
    const payload={
      "settings_id": formValues.settings_id,
    "email": formValues.email,
    "gst_number": formValues.gst_number,
    "igst_number": formValues.igst_number,
    "sgst_number": formValues.sgst_number,
    "minimum_order": formValues.minimum_order,
    "address": formValues.address
    }
    try {
      const response = await postUrl("settings/update.php", payload);
      console.log('Update response:', response);
      // Optionally handle success response here (e.g., show a success message)
    } catch (error) {
      console.error('Error updating settings:', error);
      // Optionally handle error response here (e.g., show an error message)
    }
  };

  // Fetch settings on component mount
  useEffect(() => {
    listSetting();
  }, []);

  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="page-inner">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-head-row">
                    <div className="card-title">
                      <h3>Settings</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="main-form full">
                    <div className="row">
                      {/* Email Field */}
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label htmlFor="email" className="col-md-3 control-label">
                              Email
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                placeholder="Enter Email"
                                value={formValues.email}
                                onChange={handleInputChange}
                              />
                              <span className="text-danger">
                                {/* Error message */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* GST Number Field */}
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label htmlFor="gst_number" className="col-md-3 control-label">
                              GST Number
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="text"
                                className="form-control"
                                id="gst_number"
                                name="gst_number"
                                placeholder="Enter GST Number"
                                value={formValues.gst_number}
                                onChange={handleInputChange}
                              />
                              <span className="text-danger">
                                {/* Error message */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* IGST Number Field */}
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label htmlFor="igst_number" className="col-md-3 control-label">
                              IGST Number
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="text"
                                className="form-control"
                                id="igst_number"
                                name="igst_number"
                                placeholder="Enter IGST Number"
                                value={formValues.igst_number}
                                onChange={handleInputChange}
                              />
                              <span className="text-danger">
                                {/* Error message */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Address Field */}
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label htmlFor="address" className="col-md-3 control-label">
                              Address
                            </label>
                            <div className="col-md-5 form123">
                              <textarea
                                className="form-control"
                                id="address"
                                name="address"
                                placeholder="Enter Address"
                                value={formValues.address}
                                onChange={handleInputChange}
                              />
                              <span className="text-danger">
                                {/* Error message */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn123"
                          onClick={handleUpdate}
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          className="btn12"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
