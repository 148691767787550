import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HiOutlineRefresh } from "react-icons/hi";
import { MdReport } from "react-icons/md";
import "bootstrap/js/dist/dropdown";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Form, FormControl, Button } from "react-bootstrap";
function Report1() {
  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="panel-header bg-white shadow-sm">
          <div className="table-inner py-3 mb-5">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div className="">
                <div className="header d-sm-flex align-items-center text-center page-header">
                  <h3
                    className="banner-title"
                    style={{
                      marginLeft: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MdReport
                      style={{
                        marginLeft: "25px",
                        height: "25px",
                        color: "darkslateblue",
                      }}
                    />
                    Statistics Report
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-inner transaction-details">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-title">
                  <Form
                    className="d-flex"
                    style={{
                      width: "450px",
                      padding: "10px",
                      marginLeft: "800px",
                    }}
                  >
                    <FormControl
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                    />
                    <Button className="srcbt" type="submit">
                      Search
                    </Button>
                  </Form>
                  <div className="ml-auto">
                    <div className="ml-auto d-flex align-items-center"></div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive dataTables_scroll">
                    <table
                      id="basic-datatables"
                      className="display table table-hover table-fixed"
                    >
                      <thead>
                        <tr>
                          <th>Statistics Name</th>
                          <th>Value</th>

                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Order Sales</td>
                          <td>0.000</td>

                          <td className="text-right">
                            <div className="form-button-action">
                              <button
                                data-toggle="tooltip"
                                title=""
                                className="btn btn-link "
                                data-original-title="Remove"
                              >
                                <HiOutlineRefresh />
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Order Complete</td>
                          <td>0.000</td>
                          <td className="text-right">
                            <div className="form-button-action">
                              <button
                                data-toggle="tooltip"
                                title=""
                                className="btn btn-link "
                                data-original-title="Remove"
                              >
                                <HiOutlineRefresh />
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Orders Pending</td>
                          <td>0.000</td>
                          <td className="text-right">
                            <div className="form-button-action">
                              <button
                                data-toggle="tooltip"
                                title=""
                                className="btn btn-link "
                                data-original-title="Remove"
                              >
                                <HiOutlineRefresh />
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Out Of Products</td>
                          <td>0.000</td>
                          <td className="text-right">
                            <div className="form-button-action">
                              <button
                                data-toggle="tooltip"
                                title=""
                                className="btn btn-link "
                                data-original-title="Remove"
                              >
                                <HiOutlineRefresh />
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Order Processing</td>
                          <td>0.000</td>
                          <td className="text-right">
                            <div className="form-button-action">
                              <button
                                data-toggle="tooltip"
                                title=""
                                className="btn btn-link "
                                data-original-title="Remove"
                              >
                                <HiOutlineRefresh />
                              </button>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Return</td>
                          <td>0.000</td>
                          <td className="text-right">
                            <div className="form-button-action">
                              <button
                                data-toggle="tooltip"
                                title=""
                                className="btn btn-link "
                                data-original-title="Remove"
                              >
                                <HiOutlineRefresh />
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Report1;
