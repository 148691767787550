import React from "react";
import "bootstrap/js/dist/dropdown";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { LuListMinus } from "react-icons/lu";

function Customers() {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [members, setMembers] = useState([
    {
      id: 1,
      name: "AravindKumar",
      email: "demo@gmail.com",
      phonenumber: "2365897454",
    },
    {
      id: 2,
      name: "AravindKumar",
      email: "demo@gmail.com",
      phonenumber: "2365897454",
    },
  ]);
  const handleNavigate = () => {
    navigate("/OrderList");
  };

  const handleDelete = (id) => {
    setMembers(members.filter((member) => member.id !== id));
  };
  return (
    <div className="main-panel mt-0">
      <div className="content mt-0">
        <div className="panel-header bg-white shadow-sm mt-0">
          <div className="table-inner  py-2 my-2">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div className="">
                <div className="header d-sm-flex align-items-center  text-center">
                  <h3
                    className=" banner-title"
                    style={{
                      marginLeft: "10px",
                      alignItems: "center ",
                      justifyContent: "center",
                    }}
                  >
                    <i
                      class="bi bi-person-square"
                      style={{
                        marginLeft: "20px",
                        height: "28px",
                        color: "darkslateblue",
                      }}
                    ></i>{" "}
                    List Member Management
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-inner transaction-details">
          <div className="row">
            <div className="col-12">
              <div className="card mb-0">
                {/* <div className="card-header">
                  <div className="card-head-row justify-content-between">
                    <div className="card-title"></div>
                  </div>
                </div> */}

                <div className="card-body">
                  <div className="table-responsive dataTables_scroll">
                    <table
                      id="basic-datatables"
                      className="display table table-hover table-fixed"
                    >
                      <thead>
                        <tr>
                          <th>S.NO</th>
                          <th>Customer Name</th>
                          <th>Email</th>
                          <th>Phone Number</th>
                          <th>Order History</th>

                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {members.map((member) => (
                          <tr key={member.id}>
                            <td>{member.id}</td>
                            <td>{member.name}</td>
                            <td>{member.email}</td>
                            <td>{member.phonenumber}</td>
                            <td>
                              <button
                                style={{
                                  border: "none",
                                  backgroundColor: "white",
                                }}
                                onClick={handleNavigate}
                              >
                                <LuListMinus
                                  style={{ width: "20px", height: "20px" }}
                                />
                              </button>
                            </td>

                            <td className="text-right">
                              <div className="form-button-action">
                                <Link
                                  to="/"
                                  data-toggle="tooltip"
                                  title=""
                                  className="btn btn-link btn-primary"
                                  data-original-title="Edit"
                                >
                                  <i className="bi bi-pencil"></i>
                                </Link>
                                <button
                                  onClick={() => handleDelete(member.id)}
                                  data-toggle="tooltip"
                                  title=""
                                  className="btn btn-link btn-danger"
                                  data-original-title="Remove"
                                >
                                  <i className="bi bi-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customers;
