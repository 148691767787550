import React, { useEffect, useState } from 'react';
import { getUrl, postUrl } from '../apiservice/api';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const navigate=useNavigate();
  const [formValues, setFormValues] = useState({
    company_name: "",
    phone_number: "",
    whatsapp_number: "",
    address: "",
    gst_number: "",
    email: "",
    "profile_id":"profile_66d00383b20353.09460323",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };
  const handleUpdate = async () => {
    try {
      // Prepare the payload
      const payload = {
        profile_id: formValues.profile_id,
        company_name: formValues.company_name,
        phone_number: formValues.phone_number,
        whatsapp_number: formValues.whatsapp_number,
        email:formValues.email,
        address: formValues.address,
        gst_number: formValues.gst_number
      };
  
      // Send the payload to the API
      const response = await postUrl("profile/update.php", payload);
      console.log(response);
  
      // Handle success response (e.g., show a success message or navigate away)
    } catch (error) {
      console.error('Update failed:', error);
      // Handle error response (e.g., show an error message)
    }
  };
  console.log("formvalues",formValues)
  async function listProfile() {
    try {
      const response=await postUrl("profile/listbyID.php",{"profile_id":"profile_66d00383b20353.09460323",});
      console.log(response.data)
      setFormValues(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(()=>{
    listProfile()
  },[])
  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="page-inner">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-head-row">
                    <div className="card-title">
                      <h3>Profile</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="main-form full">
                    <div className="row">
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label htmlFor="companyname" className="col-md-3 control-label">
                              Company Name
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="text"
                                className="form-control"
                                name="company_name"
                                id="companyname"
                                placeholder="Company name"
                                value={formValues.company_name}
                                onChange={handleChange}
                              />
                              <span className="text-danger">
                                {/* Error message */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label htmlFor="phonenumber" className="col-md-3 control-label">
                              Phone Number
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="text"
                                className="form-control"
                                id="phonenumber"
                                name="phone_number"
                                placeholder="Phone number"
                                value={formValues.phone_number}
                                onChange={handleChange}
                              />
                              <span className="text-danger">
                                {/* Error message */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label htmlFor="whatsappnumber" className="col-md-3 control-label">
                              Whatsapp Number
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="text"
                                className="form-control"
                                id="whatsappnumber"
                                name="whatsapp_number"
                                placeholder="Whatsapp number"
                                value={formValues.whatsapp_number}
                                onChange={handleChange}
                              />
                              <span className="text-danger">
                                {/* Error message */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label htmlFor="whatsappnumber" className="col-md-3 control-label">
                            Email
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="text"
                                className="form-control"
                                id="whatsappnumber"
                                name="email"
                                placeholder="Email"
                                value={formValues.email}
                                onChange={handleChange}
                              />
                              <span className="text-danger">
                                {/* Error message */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label htmlFor="address" className="col-md-3 control-label">
                              Address
                            </label>
                            <div className="col-md-5 form123">
                              <textarea
                                className="form-control"
                                id="address"
                                name="address"
                                placeholder="Address"
                                value={formValues.address}
                                onChange={handleChange}
                              />
                              <span className="text-danger">
                                {/* Error message */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label htmlFor="gstnumber" className="col-md-3 control-label">
                              GST Number
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="text"
                                className="form-control"
                                id="gstnumber"
                                name="gst_number"
                                placeholder="GST number"
                                value={formValues.gst_number}
                                onChange={handleChange}
                              />
                              <span className="text-danger">
                                {/* Error message */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn123"
                          onClick={handleUpdate}
                        >
                          update
                        </button>
                        <button type="button" className="btn12" onClick={()=>{navigate(-1)}}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
