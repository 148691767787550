import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postUrl } from "../apiservice/api";
import { UserContext } from "../context/UserProvider ";

function AddSubcategory() {
  const navigate=useNavigate();
  const{userDetails}=useContext(UserContext);
  console.log(userDetails)
  const location = useLocation();
  const {subcatagory,type} = location.state || {};
  const main_catagory_id=localStorage.getItem("main_catagory_id");
  
console.log("main catagory id in addsub catagory page",main_catagory_id)
console.log("type",type);
console.log("subcatgory",subcatagory);
  // State to store form values
  const [categoryName, setCategoryName] = useState("");
  const[crtCategoryname,setCrtCategoryname]=useState(subcatagory?subcatagory[0].catagory_name:'')

  // Handler for input changes
  const handleInputChange = (e) => {
    if(type==="edit"){
      setCrtCategoryname(e.target.value);
    }
    else{
      setCategoryName(e.target.value);
    }
    console.log("cateforyName",categoryName);
    console.log("crtCaegoryname",crtCategoryname);
  };

  // Handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    // You can handle form submission here, e.g., send data to an API
    const data = {
      catagory_name: categoryName,
      maincatagory_id: main_catagory_id,
    }; // Replace with actual data

    try {
      const result = await postUrl("subcatagory/insert.php", data);
      console.log(result); // Handle the response data
      setCategoryName("");
      navigate(-1);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const handleUpdate=async(e)=>{
    e.preventDefault();
    // You can handle form submission here, e.g., send data to an API
    const data = {
      "subcatagory_id":subcatagory[0].subcatagory_id,
      "catagory_name":crtCategoryname
  
  } // Replace with actual data

    try {
      const result = await postUrl("subcatagory/update.php", data);
      console.log(result); // Handle the response data
      setCrtCategoryname("");
      navigate(-1);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  }

  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="page-inner">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-head-row">
                    <div className="card-title">
                      <h3>Add Sub Category</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="main-form full">
                    <div className="row">
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label
                              htmlFor="category-name"
                              className="col-md-3 control-label"
                            >
                              Category Name
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="text"
                                className="form-control"
                                id="category-name"
                                value={type==="edit"?crtCategoryname:categoryName}
                                onChange={handleInputChange}
                                required
                                placeholder="Category Name"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 d-flex justify-content-end">
                        {type==="edit"?<button type="button" onClick={handleUpdate} className="btn123" disabled={userDetails.update_product===0?true:false} 
                                style={{cursor:userDetails.update_product==0?"not-allowed":"pointer"}}>
                          update  
                        </button>:<button type="button" onClick={handleSubmit} className="btn123" disabled={userDetails.add_product===0?true:false} 
                                style={{cursor:userDetails.add_product==0?"not-allowed":"pointer"}}>
                          Save
                        </button>}
                        <button type="button" className="btn12" onClick={()=>{navigate(-1)}}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSubcategory;
