import React, { useState } from "react";
import { postUrl } from "../apiservice/api"; // Adjust the import path as needed
import { useNavigate } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function About() {
  const navigate = useNavigate();
  // State to manage form input values
  const [formValues, setFormValues] = useState({
    title: "",
    content: "",
  });

  // State to manage update form and type of operation
  const [updateForm, setUpdateForm] = useState({});
  const [type, setType] = useState("create");

  // Handle input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (type === "create") {
      setFormValues((prevValues) => ({
        ...prevValues,
        [id]: value,
      }));
    } else if (type === "edit") {
      setUpdateForm((prevValues) => ({
        ...prevValues,
        [id]: value,
      }));
    }
    console.log('formDetails', formValues);
    console.log('updateForm', updateForm);
  };

  // Handle editor content changes
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    if (type === "create") {
      setFormValues((prevValues) => ({
        ...prevValues,
        content: data,
      }));
    } else if (type === "edit") {
      setUpdateForm((prevValues) => ({
        ...prevValues,
        content: data,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formValues.title || !formValues.content) {
      alert("All fields are required");
      return;
    }
    const data = {
      title_name: formValues.title,
      about_content: formValues.content,
    };

    try {
      const response = await postUrl("aboutcontent/insert.php", data); // Adjust URL as needed
      console.log(response);
      setUpdateForm(response.data);
      setType("edit");
    } catch (error) {
      console.log(error.name, "==>", error.message);
    }
  };

  // Handle update
  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = {
      content_id: updateForm.content_id,
      title_name: updateForm.title_name,
      about_content: updateForm.about_content,
    };

    try {
      const response = await postUrl("aboutcontent/update.php", data); // Adjust URL as needed
      console.log(response);
      if (response.status === 200) {
        alert("Update successful");
      } else {
        alert("Update failed");
      }
    } catch (error) {
      console.log(error.name, "==>", error.message);
    }
  };

  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="page-inner">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-head-row">
                    <div className="card-title">
                      <h3>{type === "edit" ? "Edit About Content" : "Add About Content"}</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="main-form full">
                    <div className="row">
                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label htmlFor="title" className="col-md-3 control-label">
                              TITLE NAME
                            </label>
                            <div className="col-md-5 form123">
                              <input
                                type="text"
                                className="form-control"
                                id={type === "edit" ? "title_name" : "title"}
                                required
                                placeholder="Title"
                                value={type === "edit" ? updateForm.title_name : formValues.title}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="input-box form-group required">
                          <div className="row">
                            <label htmlFor="content" className="col-md-3 control-label">
                              CONTENT
                            </label>
                            <div className="col-md-5 form123">
                              <CKEditor
                                editor={ClassicEditor}
                                data={type === "edit" ? updateForm.about_content : formValues.content}
                                onChange={handleEditorChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 d-flex justify-content-end">
                        {type === "edit" ? (
                          <button type="submit" className="btn123" onClick={handleUpdate}>
                            Update
                          </button>
                        ) : (
                          <button type="submit" className="btn123" onClick={handleSubmit}>
                            Submit
                          </button>
                        )}
                        <button type="button" className="btn12" onClick={() => navigate(-1)}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
