import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Layout from "./Pages/Layout";
import Dashboard from "./Pages/Dashboard";
import Customers from "./Pages/Customers";
import Products from "./Pages/Products";
import Offers from "./Pages/Offers";
import ProductList from "./Pages/ProductList";
import Coupon from "./Pages/Coupon";
import OrderList from "./Pages/OrderList";
import AddUser from "./Pages/AddUser";
import Furniture from "./Pages/Furniture";
import Report1 from "./Pages/Report1";
import User from "./Pages/User";
import Stock from "./Pages/Stock";
import Orders from "./Pages/Orders";
import Invoice from "./Pages/Invoice";
import AddCoupon from "./Pages/AddCoupon";
import AddMaincategory from "./Pages/AddMaincategory";
import AddSubcategory from "./Pages/AddSubcategory";
import AddnewProduct from "./Pages/AddnewProduct";
import Deliveryboy from "./Pages/Deliveryboy";
import Adddb from "./Pages/Adddb";
import Settings from "./Pages/Settings";
import Login from "./Pages/Login";
import Banner from "./Pages/Banner";
import About from "./Pages/About";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Terms from "./Pages/Terms";
import { UserContext } from "./context/UserProvider ";
import Profile from "./Pages/Profile";

function App() {
  const{userDetails,setStatus}=useContext(UserContext);
  return (
    <Router>
      <Routes>
        {userDetails===null ? ( 
          <>
          <Route path="/" element={<Login  />} />
          <Route path="*" element={<Login  />} />
          </>
          
          
        ) : (
          <Route element={<Layout/>}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/products" element={<Products />} />
            <Route path="/stock" element={<Stock />} />
            <Route path="/user" element={<User />} />
            <Route path="/deliveryboy" element={<Deliveryboy />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/offers" element={<Offers />} />
            <Route path="/addcoupon" element={<AddCoupon />} />
            <Route path="/productlist" element={<ProductList />} />
            <Route path="/coupon" element={<Coupon />} />
            <Route path="/orderlist" element={<OrderList />} />
            <Route path="/adduser" element={<AddUser />} />
            <Route path="/subproduct" element={<Furniture />} />
            <Route path="/report1" element={<Report1 />} />
            <Route path="/addMaincategory" element={<AddMaincategory />} />
            <Route path="/addsubcategory" element={<AddSubcategory />} />
            <Route path="/addnewproduct" element={<AddnewProduct />} />
            <Route path="/adddb" element={<Adddb />} />
            <Route path="/invoice" element={<Invoice />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/banner" element={<Banner />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/profile" element={<Profile/>}/>
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        )}
      </Routes>
    </Router>
  );
}

export default App;
