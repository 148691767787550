import React from "react";

function Adddb() {
  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="page- py-2 mb-2">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-head-row">
                    <div className="card-title">
                      <h3>Add Delivery Boy</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form class="main-form full">
                    <div class="row">
                      <div class="col-12">
                        <div class="input-box form-group required">
                          <div class="row">
                            <label
                              for="coupan-name"
                              class="col-md-3 control-label"
                            >
                              Name
                            </label>
                            <div class="col-md-5 form123">
                              <input
                                type="text"
                                class="form-control"
                                id="coupan-code"
                                required
                                placeholder="Name"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="input-box form-group required">
                          <div class="row">
                            <label
                              for="coupan-code"
                              class="col-md-3 control-label"
                            >
                              Email
                            </label>
                            <div className="col-md-5  form123">
                              <input
                                type="text"
                                class="form-control"
                                id="coupan-code"
                                required
                                placeholder="Email"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="input-box form-group required">
                          <div class="row">
                            <label
                              for="coupan-code"
                              class="col-md-3 control-label"
                            >
                              Phone Number
                            </label>
                            <div className="col-md-5  form123">
                              <input
                                type="text"
                                class="form-control"
                                id="coupan-code"
                                required
                                placeholder="Phone Number"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="input-box form-group required">
                          <div class="row">
                            <label
                              for="coupan-code"
                              class="col-md-3 control-label"
                            >
                              Password
                            </label>
                            <div className="col-md-5  form123">
                              <input
                                type="text"
                                class="form-control"
                                id="coupan-code"
                                required
                                placeholder="Password"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="input-box form-group required">
                          <div class="row">
                            <label
                              for="coupan-code"
                              class="col-md-3 control-label"
                            >
                              Bank Details
                            </label>
                            <div className="col-md-5  form123">
                              <textarea
                                type="text"
                                class="form-control"
                                id="coupan-code"
                                required
                                placeholder="Bank Details"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="input-box form-group required">
                          <div class="row">
                            <label
                              for="coupan-code"
                              class="col-md-3 control-label"
                            >
                              ID Proof
                            </label>
                            <div className="col-md-5  form123">
                              <input
                                type="text"
                                class="form-control"
                                id="coupan-code"
                                required
                                placeholder="ID Proof"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="input-box form-group required">
                          <div class="row">
                            <label
                              for="status-dropdown"
                              class="col-md-3 control-label"
                            >
                              Allotment City
                            </label>
                            <div class="col-md-5 form123">
                              <select
                                class="form-control"
                                id="status-dropdown"
                                required
                              >
                                <option value="">Select City</option>
                                <option value="active">Virudhunagr</option>
                                <option value="inactive">Sivakasi</option>
                                <option value="pending">Sattur</option>
                                <option value="expired">Tenkasi</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="input-box form-group required">
                          <div class="row">
                            <label
                              for="coupan-code"
                              class="col-md-3 control-label"
                            >
                              Image
                            </label>
                            <div className="col-md-5  form123">
                              <input
                                type="file"
                                class="form-control"
                                id="coupan-code"
                                required
                                placeholder=""
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 d-flex justify-content-end">
                        <button class="btn123">Submit</button>
                        <button class="btn12">Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adddb;
