import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postUrl } from "../apiservice/api";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import axios from "axios";

import "../css/AddnewProducts.css";

function AddnewProduct() {
  const navigate = useNavigate();
  const location = useLocation();
  const { subcatagory_id, type, product } = location.state || {};
  console.log("sproduct", subcatagory_id);
  const [formData, setFormData] = useState({
    productCode: "",
    productName: "",
    productColor: "",
    sizeOrUnit: "",
    rate: "",
    description: "",
    image1: "",
    image2: "",
    image3: "",
    image4: "",
  });

  const [crtFormData, setCrtFormData] = useState(product ? product[0] : "");
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (type === "edit") {
      if (files) {
        setCrtFormData({ ...crtFormData, [name]: files[0] });
      } else {
        setCrtFormData({ ...crtFormData, [name]: value });
      }
    } else {
      if (files) {
        setFormData({ ...formData, [name]: files[0] });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    }

    console.log("FormData", formData);
    console.log("crtFormData", crtFormData);
  };
  // const validateForm = () => {
  //   const newErrors = {};
  //   if (!formData.productCode)
  //     newErrors.productCode = "Product Code is required";
  //   if (!formData.productName)
  //     newErrors.productName = "Product Name is required";
  //   if (!formData.productColor)
  //     newErrors.productColor = "Product Color is required";
  //   if (!formData.sizeOrUnit)
  //     newErrors.sizeOrUnit = "Size (or) Unit is required";
  //   if (!formData.rate) newErrors.rate = "Rate is required";
  //   if (!formData.description)
  //     newErrors.description = "Description is required";
  //   if (!formData.image0) newErrors.image0 = "Image is required";
  //   if (!formData.image1) newErrors.image1 = "Image 1 is required";
  //   if (!formData.image2) newErrors.image2 = "Image 2 is required";
  //   if (!formData.image4) newErrors.image4 = "Image 3 is required";

  //   setErrors(newErrors);
  //   return Object.keys(newErrors).length === 0;
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("product_code", formData.productCode);
    data.append("product_name", formData.productName);
    data.append("color", formData.productColor);
    data.append("unit", formData.sizeOrUnit);
    data.append("rate", formData.rate);
    data.append("description", formData.description);
    data.append("sub_catagory_id", subcatagory_id); // Make sure subcatagory_id is defined

    if (formData.image1) data.append("image1", formData.image1);
    if (formData.image2) data.append("image2", formData.image2);
    if (formData.image3) data.append("image3", formData.image3);
    if (formData.image4) data.append("image4", formData.image4);

    try {
      const response = await axios.post(
        "https://www.vedanthfashions.com/api/product/insert.php",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Response received:", response);

      if (response.data.status === 200) {
        alert("Product added successfully");
        setFormData({
          productCode: "",
          productName: "",
          productColor: "",
          sizeOrUnit: "",
          rate: "",
          description: "",
          image1: null,
          image2: null,
          image3: null,
          image4: null,
        });
        navigate(-1);
      } else {
        alert("Failed to add product");
      }
    } catch (error) {
      console.error("Error uploading files:", error.response?.data || error);
      alert("An error occurred while adding the product. Please try again.");
    }
  };

  const handleUpdate = async (e) => {
    console.log("update subCatagoryId", subcatagory_id);
    e.preventDefault(); // Create a new FormData object
    const updatedData = new FormData();

    // Append form fields to FormData
    updatedData.append("product_code", crtFormData.product_code || "");
    updatedData.append("product_name", crtFormData.product_name || "");
    updatedData.append("color", crtFormData.color || "");
    updatedData.append("unit", crtFormData.unit || "");
    updatedData.append("rate", crtFormData.rate || "");
    updatedData.append("description", crtFormData.description || "");
    updatedData.append("product_id", crtFormData.product_id);

    // Append image files if they exist
    if (crtFormData.image1) updatedData.append("image1", crtFormData.image1);
    if (crtFormData.image2) updatedData.append("image2", crtFormData.image2);
    if (crtFormData.image3) updatedData.append("image3", crtFormData.image3);
    if (crtFormData.image4) updatedData.append("image4", crtFormData.image4);

    try {
      const response = await axios.post(
        "https://www.vedanthfashions.com/api/product/update.php",
        updatedData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("response", response);

      if (response.data.status === 200) {
        alert("Product updated successfully");
        navigate(-1);
      } else {
        alert("Failed to update product");
      }
    } catch (error) {
      console.error("Error updating product", error);
    }
  };

  const [previewUrls, setPreviewUrls] = useState({});
  console.log("formdata", formData);
  console.log("crtFormdata", crtFormData);
  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrls((prev) => ({
          ...prev,
          [name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-head-row">
                      <div className="card-title">
                        <h3>Add New Product</h3>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <form className="main-form full">
                      <div className="row">
                        <div className="col-12">
                          <div className="input-box form-group required">
                            <div className="row">
                              <label
                                htmlFor="productCode"
                                className="col-md-3 control-label"
                              >
                                Product Code
                              </label>
                              <div className="col-md-5 form123">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="productCode"
                                  name={
                                    type === "edit"
                                      ? "product_code"
                                      : "productCode"
                                  }
                                  value={
                                    type === "edit"
                                      ? crtFormData.product_code
                                      : formData.productCode
                                  }
                                  onChange={handleChange}
                                  required
                                  placeholder="Product Code"
                                />
                                {errors.productCode && (
                                  <span className="text-danger">
                                    {errors.productCode}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-box form-group required">
                            <div className="row">
                              <label
                                htmlFor="productName"
                                className="col-md-3 control-label"
                              >
                                Product Name
                              </label>
                              <div className="col-md-5 form123">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="productName"
                                  name={
                                    type === "edit"
                                      ? "product_name"
                                      : "productName"
                                  }
                                  value={
                                    type === "edit"
                                      ? crtFormData.product_name
                                      : formData.productName
                                  }
                                  onChange={handleChange}
                                  required
                                  placeholder="Product Name"
                                />
                                {errors.productName && (
                                  <span className="text-danger">
                                    {errors.productName}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-box form-group required">
                            <div className="row">
                              <label
                                htmlFor="productColor"
                                className="col-md-3 control-label"
                              >
                                Product Color
                              </label>
                              <div className="col-md-5 form123">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="productColor"
                                  name={
                                    type === "edit" ? "color" : "productColor"
                                  }
                                  value={
                                    type === "edit"
                                      ? crtFormData.color
                                      : formData.productColor
                                  }
                                  onChange={handleChange}
                                  required
                                  placeholder="Product Color"
                                />
                                {errors.productColor && (
                                  <span className="text-danger">
                                    {errors.productColor}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-box form-group required">
                            <div className="row">
                              <label
                                htmlFor="sizeOrUnit"
                                className="col-md-3 control-label"
                              >
                                Size (or) Unit
                              </label>
                              <div className="col-md-5 form123">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="sizeOrUnit"
                                  name={type === "edit" ? "unit" : "sizeOrUnit"}
                                  value={
                                    type === "edit"
                                      ? crtFormData.unit
                                      : formData.sizeOrUnit
                                  }
                                  onChange={handleChange}
                                  required
                                  placeholder="Size (or) Unit"
                                />
                                {errors.sizeOrUnit && (
                                  <span className="text-danger">
                                    {errors.sizeOrUnit}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-box form-group required">
                            <div className="row">
                              <label
                                htmlFor="rate"
                                className="col-md-3 control-label"
                              >
                                Rate
                              </label>
                              <div className="col-md-5 form123">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="rate"
                                  name={type === "edit" ? "rate" : "rate"}
                                  value={
                                    type === "edit"
                                      ? crtFormData.rate
                                      : formData.rate
                                  }
                                  onChange={handleChange}
                                  required
                                  placeholder="Rate"
                                />
                                {errors.rate && (
                                  <span className="text-danger">
                                    {errors.rate}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-box form-group required">
                            <div className="row">
                              <label
                                htmlFor="description"
                                className="col-md-3 control-label"
                              >
                                Description
                              </label>
                              <div className="col-md-5 form123">
                                <textarea
                                  className="form-control"
                                  id="description"
                                  name={
                                    type === "edit"
                                      ? "description"
                                      : "description"
                                  }
                                  value={
                                    type === "edit"
                                      ? crtFormData.description
                                      : formData.description
                                  }
                                  onChange={handleChange}
                                  required
                                  placeholder="Description"
                                />
                                {errors.description && (
                                  <span className="text-danger">
                                    {errors.description}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-box form-group required">
                            <div className="row">
                              {["image1", "image2", "image3", "image4"].map(
                                (img, index) => {
                                  const fileInputRef = React.createRef(); // Create a ref for the file input

                                  return (
                                    <div
                                    style={{display:"flex",justifyContent:"space-around"}}
                                      key={index}
                                      className="col-md-3 image-upload-section"
                                    >
                                      <input
                                        type="file"
                                        ref={fileInputRef}
                                        className="form-control image-input"
                                        id={img}
                                        name={img}
                                        onChange={(event) => {
                                          handleFileChange(event);
                                          handleChange(event);
                                        }}
                                        required
                                        style={{ display: "none" }}
                                      />
                                      {errors[img] && (
                                        <span className="text-danger">
                                          {errors[img]}
                                        </span>
                                      )}
                                      {previewUrls[img] && (
                                        <div className="image-preview">
                                          <img
                                            src={previewUrls[img]}
                                            alt={`preview`}
                                            aria-live="polite"
                                          />
                                        </div>
                                      )}
                                      {/* <div style={{display:"flex",justifyContent:"space-around"}}> */}
                              {/* { */}
                                {/* // ["image1","image2","image3","image4"].map((img,index)=>( */}
                                  {type === "edit" && !previewUrls[img] && (
                                    <div className="image-preview">
                                      <img
                                        src={`https://www.vedanthfashions.com/api/product/images/${crtFormData[img]}`}
                                        alt={`preview`}
                                        aria-live="polite"
                                      />
                                    </div>
                                  )}
                                {/* // )) */}
                              {/* } */}
                              {/* </div> */}
                                      <button
                                        type="button"
                                        className="btn btn-primary upload-button"
                                        onClick={() =>
                                          fileInputRef.current.click()
                                        } // Use ref to trigger click
                                        aria-label={`Upload Image ${index + 1}`}
                                      >
                                        {`Image ${index + 1}`}
                                      </button>
                                    </div>
                                  );
                                }
                              )}
                              
                            </div>
                          </div>
                        </div>

                        <div className="mt-4 d-flex justify-content-end">
                          {type === "edit" ? (
                            <button
                              type="button"
                              className="btn123"
                              onClick={handleUpdate}
                            >
                              Update
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn123"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          )}
                          <button type="button" className="btn12">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddnewProduct;
