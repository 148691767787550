import React from "react";

function Invoice() {
  const handlePrint = () => {
    window.print();
  };
  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="page-inner transaction-details">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">
                    <i className="fas fa-info-circle"></i> INVOICE (#515345)
                  </div>
                </div>

                <div className="card-body">
                  <div className="table-responsive order-details">
                    <table className="display table table-bordered">
                      <thead>
                        <tr>
                          <th>Payment Address</th>
                          <th>Shipping Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            John Doe
                            <br />
                            150-A Appolo apartment,
                            <br />
                            opp. Hopewell Junction,
                            <br />
                            Allen st Road,
                            <br />
                            New York-405001.
                          </td>
                          <td>
                            John Doe
                            <br />
                            150-A Appolo apartment,
                            <br />
                            opp. Hopewell Junction,
                            <br />
                            Allen st Road,
                            <br />
                            New York-405001.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="display table table-bordered mt-3">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Brands</th>
                          <th className="text-right">Quantity</th>
                          <th className="text-right">Unit Price</th>
                          <th className="text-right">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Deluxe Solid hat Cap</td>
                          <td>D & G</td>
                          <td className="text-right">1</td>
                          <td className="text-right">$89.00</td>
                          <td className="text-right">$89.00</td>
                        </tr>
                        <tr>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td colSpan="1" className="text-right">
                            Sub-Total
                          </td>
                          <td className="text-right">$89.00</td>
                        </tr>

                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td colSpan="1" className="text-right">
                            Flat Shipping Rate
                          </td>
                          <td className="text-right">$5.00</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td colSpan="1" className="text-right">
                            Total
                          </td>
                          <td className="text-right">$94.00</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="demo123">
                      <button
                        className="btn12"
                        type="button"
                        onClick={handlePrint}
                      >
                        Print
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Invoice;
