import React, { useContext, useEffect, useState } from "react";
import bannerimg from "../Assets/544-5445462_people-icons-png-flat-person-icon-png-transparent.png";
import axios from "axios";
import { getUrl, postUrl } from "../apiservice/api";
import { UserContext } from "../context/UserProvider ";

function Banner() {
  const{userDetails}=useContext(UserContext);
  // State to hold the selected file
  const [file, setFile] = useState(null);
  const [banners, setBanners] = useState([]);
  const [uploadStatus, setUplaodStatus] = useState(false);
  const[preview,setPreview]=useState(null);

  // Event handler for file input change
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    // You can add logic here to upload the file or preview it
    console.log("Selected file:", selectedFile);
    const previewURL = URL.createObjectURL(selectedFile);
    setPreview(previewURL);
  };

  const uploadBanner = async () => {
    const formData = new FormData();
    formData.append("fileToUpload", file);
    try {
      const response = await axios.post(
        "https://www.vedanthfashions.com/api/fileUpoader/upload.php",
        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("response", response);

      if (response.data.status === 200) {
        setUplaodStatus((curr) => !curr);
        alert("banner upload successfully");
      } else {
        alert("bannner upload failed");
      }
    } catch (error) {
      console.error("Error updating product", error);
    }
  };

  const handleBannerDelete = async (id) => {
    const data = { file_id: id };
    try {
      const response = await postUrl("fileUpoader/bannerdelete.php", data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    setBanners(banners.filter((sin) => sin.file_id !== id));
  };
  const bannerList = async () => {
    try {
      const result = await getUrl("fileUpoader/bannerlist.php");
      console.log(result.data);
      setBanners(result.data);
    } catch (error) {
      console.log(error.name, ":", error.message);
    }
  };

  const handleActiveChange = async(id, checked) => {
    setBanners(
      banners.map((cat) =>
        cat.file_id === id ? { ...cat, active: checked ? 1 : 0 } : cat
      )
    );

    const data={file_id:id,active:checked?1:0}
    try {
      const response=await postUrl("fileUpoader/bannerupdate.php",data);
      console.log(response);
      setUplaodStatus(cur=>!cur)
    } catch (error) {
      console.log(error.name,"==>",error.message);
    }
  };

  console.log("banners",banners);

  useEffect(() => {
    bannerList();
  }, [uploadStatus,]);
  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="table-inner transaction-details">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-title">
                  <h5 className="bantabletitle">Image Upload</h5>
                  <hr />
                  <div className="ml-auto d-flex align-items-center justify-content-end">
                    <div className="col-12 bannerform">
                      <div className="input-box form-group required">
                        <div className="row upimage align-items-center">
                          <label
                            htmlFor="coupan-code"
                            className="col-md-3 control-label"
                          >
                            Upload Your Image :
                          </label>
                          <div className="col-md-5 d-flex align-items-center">
                            <input
                              type="file"
                              className="form-control"
                              id="coupan-code"
                              required
                              onChange={handleFileChange}
                            />
                           {preview!==null&&<div>
                              <img src={preview} style={{width:"auto",height:"100px",objectFit:"cover"}}/>
                            </div>}
                            <button
                                  disabled={
                                    userDetails.banner === 0
                                      ? true
                                      : false
                                  }
                                  style={{
                                    cursor:
                                      userDetails.banner == 0
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                  onClick={uploadBanner}
                                  type="submit"
                                  className="btn123"
                                >
                                  upload
                                </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="card-body">
                  <h5 className="bantabletitle">Banner Status</h5>
                  <div className="table-responsive dataTables_scroll">
                    <table
                      id="basic-datatables"
                      className="display table table-hover table-fixed"
                    >
                      <thead>
                        <tr>
                          <th>Banner Images</th>
                          <th>Active</th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(banners) &&
                          banners.map((sin, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <img
                                    src={`https://www.vedanthfashions.com/api/fileUpoader/${sin.file_name}`}
                                    alt="Single Cot"
                                    style={{ width: "auto", height: "100px" }}
                                    className="my-2"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={sin.active==1?true:false}
                                    onChange={(e) =>
                                      handleActiveChange(
                                        sin.file_id,
                                        e.target.checked
                                      )
                                    }
                                    style={{ width: "20px", height: "20px" }}
                                  />
                                </td>
                                <td className="text-right">
                                  <div className="form-button-action">
                                  <button
                                disabled={userDetails.banner===0?true:false} 
                                style={{cursor:userDetails.banner==0?"not-allowed":"pointer"}}
                                onClick={() => {
                                  handleBannerDelete(sin.file_id);
                                }}
                                  data-toggle="tooltip"
                                  title=""
                                  className="btn btn-link text-danger"
                                  data-original-title="Remove"
                                >
                                  <i className="bi bi-trash"></i>
                                </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
