import React, { useContext, useState } from "react";
import "./App.css";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { UserContext } from "../src/context/UserProvider ";

function Sidebar() {
  const { setUserDetails } = useContext(UserContext);
  const navigate = useNavigate();
  const [isSettingsDropdownOpen, setIsSettingsDropdownOpen] = useState(false);
  const [isReportDropdownOpen, setIsReportDropdownOpen] = useState(false);
  const [isContentDropdownOpen, setIsContentDropdownOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSettingsDropdown = () => {
    localStorage.removeItem("user");
    setUserDetails(null);
    navigate("/");
    setIsSettingsDropdownOpen(!isSettingsDropdownOpen);
  };

  const toggleReportDropdown = () => {
    setIsReportDropdownOpen(!isReportDropdownOpen);
  };

  const toggleContentDropdown = () => {
    setIsContentDropdownOpen(!isContentDropdownOpen);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    if (window.innerWidth < 768) {
      setIsSidebarOpen(false);
    }
    setIsSettingsDropdownOpen(false);
    setIsReportDropdownOpen(false);
    setIsContentDropdownOpen(false);
  };

  return (
    <>
      <div className={`sidebar dark-bg ${isSidebarOpen ? "active" : ""}`}>
        <div className="sidebar-wrapper scrollbar scrollbar-inner">
          <div className="sidebar-content">
            <div className="logo-header">
              <span className="inline-container">
                <p className="logo-text">Company Logo</p>
              </span>
            </div>
            <ul className="nav nav-primary">
              <li className="nav-item active">
                <Link to="/" onClick={closeSidebar}>
                  <i className="bi-house"></i>
                  <p className="nav-text">Dashboard</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/Customers" onClick={closeSidebar}>
                  <i className="bi bi-person-add"></i>
                  <p>Customers</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/products" onClick={closeSidebar}>
                  <i className="bi bi-bag"></i>
                  <p>Products Categories</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/Orders" onClick={closeSidebar}>
                  <i className="bi bi-cart4"></i>
                  <p> Orders</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/Banner" onClick={closeSidebar}>
                  <i className="bi bi-images"></i>
                  <p>Banner</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={toggleContentDropdown}>
                  <i className="bi bi-clipboard-data"></i>
                  <p>Content Management</p>
                  <span className="caret"></span>
                </Link>
                {isContentDropdownOpen && (
                  <ul className="collapse show" id="product">
                    <Link to="/about" onClick={closeSidebar}>
                      <i className="bi bi-dot"></i>
                      <p className="sub-item">About Us</p>
                    </Link>
                    <Link to="/privacypolicy" onClick={closeSidebar}>
                      <i className="bi bi-dot"></i>
                      <p className="sub-item">Privacy Policy</p>
                    </Link>
                    <Link to="/terms" onClick={closeSidebar}>
                      <i className="bi bi-dot"></i>
                      <p className="sub-item">Terms & Conditions</p>
                    </Link>
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <Link onClick={toggleReportDropdown}>
                  <i className="bi bi-person-fill"></i>
                  <p>Users</p>
                  <span className="caret"></span>
                </Link>
                {isReportDropdownOpen && (
                  <div className="collapse show" id="product">
                    <li>
                      <Link to="/User" onClick={closeSidebar}>
                        <i className="bi bi-dot"></i>
                        <p className="sub-item">User List</p>
                      </Link>
                    </li>
                  </div>
                )}
              </li>
              <li className="nav-item">
                <Link onClick={toggleSettingsDropdown}>
                  <i className="bi bi-gear"></i>
                  <p>Log Out</p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="nav-toggle">
        <button className="toggle-sidebar" onClick={toggleSidebar}>
          <i className="bi bi-list toggle-icon"></i>
        </button>
      </div>
    </>
  );
}

export default Sidebar;
