import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/js/dist/dropdown";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Form } from "react-bootstrap";

function Deliveryboy() {
  const [members, setMembers] = useState([
    {
      id: 1,
      name: "Test",
      email: "test@gmail.com",
      phonenumber: "3698521477",
    },
    {
      id: 2,
      name: "Test",
      email: "test@gmail.com",
      phonenumber: "3698521477",
    },
  ]);

  const handleDelete = (id) => {
    setMembers(members.filter((member) => member.id !== id));
  };

  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="panel-header bg-white shadow-sm">
          <div className="table-inner py-2 mb-2">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div className="">
                <div className="header d-sm-flex align-items-center text-center page-header">
                  <h3
                    className=" banner-title"
                    style={{
                      marginLeft: "10px",
                      alignItems: "center ",
                      justifyContent: "center",
                    }}
                  >
                    <i
                      class="bi bi-person-square"
                      style={{
                        marginLeft: "20px",
                        height: "28px",
                        color: "darkslateblue",
                      }}
                    ></i>{" "}
                    Delivery Boy Details
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-inner transaction-details">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-title">
                  <div className="ml-auto">
                    <div className="ml-auto d-flex align-items-center">
                      <Link data-toggle="collapse" to="/Adddb">
                        <Form inline className="demo12">
                          <button class="btn12 ">Add Delivery Boy</button>
                        </Form>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive dataTables_scroll">
                    <table
                      id="basic-datatables"
                      className="display table table-hover table-fixed"
                    >
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Delivery Boy Name</th>
                          <th>Email</th>
                          <th>Phone Number</th>
                          <th>Active</th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {members.map((member) => (
                          <tr key={member.id}>
                            <td>{member.id}</td>
                            <td>{member.name}</td>
                            <td>{member.email}</td>
                            <td>{member.phonenumber}</td>
                            <td>
                              <input
                                type="checkbox"
                                style={{ width: "20px", height: "20px" }}
                              />
                            </td>

                            <td className="text-right">
                              <div className="form-button-action">
                                <Link
                                  to="/"
                                  data-toggle="tooltip"
                                  title=""
                                  className="btn btn-link btn-primary "
                                  data-original-title="Edit"
                                >
                                  <i className="bi bi-pencil"></i>
                                </Link>
                                <button
                                  onClick={() => handleDelete(member.id)}
                                  data-toggle="tooltip"
                                  title=""
                                  className="btn btn-link btn-danger"
                                  data-original-title="Remove"
                                >
                                  <i className="bi bi-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Form inline className="demo123">
                    <button class="btn12 ">Update</button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deliveryboy;
