import axios from "axios"

export const postUrl = async (mainUrl, data) => {
    const apiUrl = `https://www.vedanthfashions.com/api/${mainUrl}`;
    try { 
      const response = await axios.post(apiUrl, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return { postUrlError: error.message };
    }
  };

  export const getUrl = async (mainUrl) => {
    const apiUrl = `https://www.vedanthfashions.com/api/${mainUrl}`;
    try {
      const response = await axios.get(apiUrl);
      return response.data; // Return only the data part of the response
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Re-throw the error to handle it in the calling function
    }
  };