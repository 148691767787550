import React from "react";
import { BiSolidOffer } from "react-icons/bi";

function AddCoupon() {
  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="panel-header bg-white shadow-sm">
          <div className="page-inner py-2 mb-2">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div className="">
                <div className="header d-sm-flex align-items-center  text-center">
                  <h1 className="page-header banner-title">
                    <BiSolidOffer style={{ color: "darkslateblue" }} /> COUPON{" "}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-inner">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-head-row">
                    <div className="card-title">
                      <h3>Add Coupon Details</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form class="main-form full">
                    <div class="row">
                      <div class="col-12">
                        <div class="input-box form-group required">
                          <div class="row">
                            <label
                              for="coupan-name"
                              class="col-md-3 control-label"
                            >
                              Coupon Name
                            </label>
                            <div class="col-md-5 form123">
                              <input
                                type="text"
                                class="form-control"
                                id="coupan-code"
                                required
                                placeholder="Coupon Name"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="input-box form-group required">
                          <div class="row">
                            <label
                              for="coupan-code"
                              class="col-md-3 control-label"
                            >
                              Coupon Code
                            </label>
                            <div className="col-md-5  form123">
                              <input
                                type="text"
                                class="form-control"
                                id="coupan-code"
                                required
                                placeholder="Coupon Code"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="input-box form-group required">
                          <div class="row">
                            <label
                              for="coupan-code"
                              class="col-md-3 control-label"
                            >
                              Start Date
                            </label>
                            <div className="col-md-5  form123">
                              <input
                                type="text"
                                class="form-control"
                                id="coupan-code"
                                required
                                placeholder="Start Date"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="input-box form-group required">
                          <div class="row">
                            <label
                              for="coupan-code"
                              class="col-md-3 control-label"
                            >
                              End Date
                            </label>
                            <div className="col-md-5  form123">
                              <input
                                type="text"
                                class="form-control"
                                id="coupan-code"
                                required
                                placeholder="End Date"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="input-box form-group required">
                          <div class="row">
                            <label
                              for="coupan-code"
                              class="col-md-3 control-label"
                            >
                              Discount Type
                            </label>
                            <div className="col-md-5  form123">
                              <input
                                type="text"
                                class="form-control"
                                id="coupan-code"
                                required
                                placeholder="Discount Type"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="input-box form-group required">
                          <div class="row">
                            <label
                              for="status-dropdown"
                              class="col-md-3 control-label"
                            >
                              Status
                            </label>
                            <div class="col-md-5 form123">
                              <select
                                class="form-control"
                                id="status-dropdown"
                                required
                              >
                                <option value="">Select Status</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                                <option value="pending">Pending</option>
                                <option value="expired">Expired</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 d-flex justify-content-end">
                        <button class="btn123">Save</button>
                        <button class="btn12">Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCoupon;
