import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link, useLocation } from "react-router-dom";
import { postUrl } from "../apiservice/api";

function Stock() {
  const location=useLocation();
  const sub_catagory_id=location.state||{};

  const[products,setProducts]=useState([]);

  const productList = async () => {
    // Data to be sent in the POST request
    const data = { sub_catagory_id: sub_catagory_id }; // Replace with actual data

    try {
      const result = await postUrl("product/stocklist.php", data);
      console.log("product list", result); // Handle the response data
      setProducts(result.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const handleChange = async(id, value) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.product_id === id ? { ...product, unit: value } : product
      )
    );

    const data = {
      "product_id":id,
      "unit":value
  };
    try {
      const result = await postUrl("product/unitupdate.php", data);
      console.log(result);
      if(result.status==200){
        console.log("stock update successfully")
      }
      else{
        alert("please check your internet connection")
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
    
  };
  
  console.log("products",products);
  useEffect(()=>{
    productList();

    // ()=>{
    //   setProducts([]);
    // }
    
  },[])
  return (
    <div className="main-panel mt-0">
      <div className="content mt-2">
        <div className="panel-header bg-white shadow-sm">
          <div className="table-inner py-2 mb-2">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div className="">
                <div className="header d-sm-flex align-items-center text-center page-header">
                  <i
                    className="bi bi-list"
                    style={{ marginLeft: "20px", height: "28px" }}
                  ></i>
                  <h3
                    className="banner-title"
                    style={{
                      marginLeft: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Stock Update
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-inner transaction-details">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-title">
                  <div className="ml-auto">
                    <div className="ml-auto d-flex align-items-center">
                      <Form inline className="demo12">
                        <Link to="/subproduct">
                          <button class="btn12 ">Back To Sub Category</button>
                        </Link>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive dataTables_scroll">
                    <table
                      id="basic-datatables"
                      className="display table table-hover table-fixed"
                    >
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Product Code</th>
                          <th>Product Name</th>
                          <th>Stock</th>
                        </tr>
                      </thead>
                      <tbody>
                        { Array.isArray(products)&&products.map((member,index) => (
                          <tr key={index}>
                            <td>{index+1}</td>
                            <td>{member.product_code}</td>
                            <td>{member.product_name}</td>
                            <td>
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                name="unit"
                                value={member.unit}
                                onChange={(e)=>{handleChange(member.product_id,e.target.value)}}
                                style={{
                                  width: "150px",
                                  height: "40px",
                                  border: "1px solid black",
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Form inline className="demo12">
                    <button class="btn12 ">Update</button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stock;
